"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Spinner = require("./Spinner");
var _Spinner2 = _interopRequireDefault(_Spinner);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
exports.default = _Spinner2.default;