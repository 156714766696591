'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticMessages = exports.commonMessages = exports.projectsApiList = exports.projectsApi = exports.userHandlingSPIssuers = exports.userHandlingSPInvestors = exports.userHandlingAdvisors = exports.userHandlingInvestors = exports.userHandlingIssuers = exports.userHandlingStrategicPartners = exports.userHandlingAdminUsers = exports.SPCreationHistory = exports.SPIssuerCreate = exports.SPIssuersList = exports.SPInvestorCreate = exports.SPInvestorsList = exports.SPWorkerCreate = exports.SPWorkers = exports.SPAdminCreate = exports.SPAdmins = exports.userHandlingSP = exports.userHandling = exports.strategicPartnersSubscriptions = exports.strategicPartnersIdSubscriptions = exports.strategicPartnersCreate = exports.adminUsersCreate = exports.strategicPartners = exports.adminUsers = exports.projectsFavorite = exports.projectRejectionsHistory = exports.projectsSPIssuer = exports.projectCreate = exports.projectEditToken = exports.projectEdit = exports.projectDetails = exports.projectsMy = exports.projectsIssuer = exports.projectsAll = exports.spProjects = exports.KYCUserIdStatus = exports.KYCUserStatus = exports.adminUserProfile = exports.userProfile = exports.userEdit = exports.user = exports.spSettings = exports.dashboard = exports.settings = exports.lostConnection = exports.projectInvestments = exports.projectDataRoomAccess = exports.strategicPartnerUserRole = exports.advisorUserRole = exports.investorUserRole = exports.issuerUserRole = exports.confirmInvestorToken = exports.confirmEmailToken = exports.restorePasswordToken = exports.confirmInvestor = exports.confirmEmail = exports.notifications = exports.restorePassword = exports.registration = exports.projects = exports.termsOfUse = exports.privacy = exports.cookie = exports.partners = exports.about = exports.login = exports.callback = exports.stubChooseRolePage = exports.stubPage = exports.homePage = exports.history = undefined;
var _history = require('history');
var _config = require('../utils/config');
var _config2 = _interopRequireDefault(_config);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var token = ':token';
var id = ':id';
var tab = ':tab';
var history = exports.history = (0, _history.createBrowserHistory)();

// ------------ Internal ------------
/* UnSecure url's */
var homePage = exports.homePage = '/homepage';
var stubPage = exports.stubPage = '/';
var stubChooseRolePage = exports.stubChooseRolePage = '/roles';
var callback = exports.callback = '/callback';
var login = exports.login = '/login';
var about = exports.about = '/about';
var partners = exports.partners = '/partners';
var cookie = exports.cookie = '/cookie';
var privacy = exports.privacy = '/privacy';
var termsOfUse = exports.termsOfUse = '/terms';
var projects = exports.projects = '/projects';
var registration = exports.registration = '/registration';
var restorePassword = exports.restorePassword = '/restore-password';
var notifications = exports.notifications = '/notifications';
var confirmEmail = exports.confirmEmail = '/confirm-email';
var confirmInvestor = exports.confirmInvestor = '/confirm-investor';
var restorePasswordToken = exports.restorePasswordToken = "".concat(restorePassword, "/").concat(token);
var confirmEmailToken = exports.confirmEmailToken = "".concat(confirmEmail, "/").concat(token);
var confirmInvestorToken = exports.confirmInvestorToken = "".concat(confirmInvestor, "/").concat(token);
var issuerUserRole = exports.issuerUserRole = "".concat(stubChooseRolePage, "/issuer");
var investorUserRole = exports.investorUserRole = "".concat(stubChooseRolePage, "/investor");
var advisorUserRole = exports.advisorUserRole = "".concat(stubChooseRolePage, "/advisor");
var strategicPartnerUserRole = exports.strategicPartnerUserRole = "".concat(stubChooseRolePage, "/strategic-partner");
var projectDataRoomAccess = exports.projectDataRoomAccess = "".concat(projects, "/project-data-room-access");
var projectInvestments = exports.projectInvestments = "".concat(projects, "/project-investments");
var lostConnection = exports.lostConnection = '/lost-connection';

/* Secure url's */
var settings = exports.settings = '/settings';
var dashboard = exports.dashboard = '/dashboard';
var spSettings = exports.spSettings = '/sp-settings';
var user = exports.user = '/user';
var userEdit = exports.userEdit = "".concat(user, "/edit");
var userProfile = exports.userProfile = "".concat(user, "/profile");
var adminUserProfile = exports.adminUserProfile = '/user/profile';
var KYCUserStatus = exports.KYCUserStatus = "".concat(user, "/kyc");
var KYCUserIdStatus = exports.KYCUserIdStatus = "".concat(KYCUserStatus, "/").concat(id);

// ------------ Projects Page

var spProjects = exports.spProjects = '/sp-projects';
var projectsAll = exports.projectsAll = "".concat(projects, "/all");
var projectsIssuer = exports.projectsIssuer = "".concat(projects, "/issuer");
var projectsMy = exports.projectsMy = "".concat(projects, "/my");
var projectDetails = exports.projectDetails = "".concat(projects, "/project-details");
var projectEdit = exports.projectEdit = "".concat(projectsIssuer, "/edit");
var projectEditToken = exports.projectEditToken = "".concat(projectsIssuer, "/edit/").concat(token);
var projectCreate = exports.projectCreate = "".concat(projectsIssuer, "/create");
var projectsSPIssuer = exports.projectsSPIssuer = "".concat(projects, "/sp-issuer");
var projectRejectionsHistory = exports.projectRejectionsHistory = "".concat(projects, "/rejections-history");
var projectsFavorite = exports.projectsFavorite = "".concat(projects, "/favorite");

// ------Users creation---------

var adminUsers = exports.adminUsers = '/admin-users';
var strategicPartners = exports.strategicPartners = '/strategic-partners';
var adminUsersCreate = exports.adminUsersCreate = "".concat(adminUsers, "/create");
var strategicPartnersCreate = exports.strategicPartnersCreate = "".concat(strategicPartners, "/create");
var strategicPartnersIdSubscriptions = exports.strategicPartnersIdSubscriptions = "".concat(strategicPartners, "/subscriptions/").concat(id, "/").concat(tab);
var strategicPartnersSubscriptions = exports.strategicPartnersSubscriptions = "".concat(strategicPartners, "/subscriptions");

// ------------ /Internal ------------

// ---- User Handling
var userHandling = exports.userHandling = '/user-handling';
var userHandlingSP = exports.userHandlingSP = "".concat(userHandling, "/sp");
var SPAdmins = exports.SPAdmins = "".concat(userHandlingSP, "/admins-list");
var SPAdminCreate = exports.SPAdminCreate = "".concat(userHandlingSP, "/admin-create");
var SPWorkers = exports.SPWorkers = "".concat(userHandlingSP, "/workers-list");
var SPWorkerCreate = exports.SPWorkerCreate = "".concat(userHandlingSP, "/worker-create");
var SPInvestorsList = exports.SPInvestorsList = "".concat(userHandlingSP, "/investors-list");
var SPInvestorCreate = exports.SPInvestorCreate = "".concat(userHandlingSP, "/investor-create");
var SPIssuersList = exports.SPIssuersList = "".concat(userHandlingSP, "/issuers-list");
var SPIssuerCreate = exports.SPIssuerCreate = "".concat(userHandlingSP, "/issuer-create");
var SPCreationHistory = exports.SPCreationHistory = "".concat(userHandlingSP, "/creation-history");

// ---- User Handling (Users Lists)--
var userHandlingAdminUsers = exports.userHandlingAdminUsers = "".concat(userHandling, "/admin-users");
var userHandlingStrategicPartners = exports.userHandlingStrategicPartners = "".concat(userHandling, "/strategic-partners");
var userHandlingIssuers = exports.userHandlingIssuers = "".concat(userHandling, "/issuers");
var userHandlingInvestors = exports.userHandlingInvestors = "".concat(userHandling, "/investors");
var userHandlingAdvisors = exports.userHandlingAdvisors = "".concat(userHandling, "/advisors");
var userHandlingSPInvestors = exports.userHandlingSPInvestors = "".concat(userHandling, "/sp-investors");
var userHandlingSPIssuers = exports.userHandlingSPIssuers = "".concat(userHandling, "/sp-issuers");

// ------------ External ------------

var projectsApi = exports.projectsApi = "".concat(_config2.default.apiProjectUrl, "/projects");
var projectsApiList = exports.projectsApiList = "".concat(projectsApi);
var commonMessages = exports.commonMessages = 'https://static.dev.finbursa.com/locales/en/common.json';
var staticMessages = exports.staticMessages = 'https://static.dev.finbursa.com/locales/en/static.json';

// ------------ External ------------