'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var platforms = {
  STATIC: 'STATIC',
  ADMIN: 'ADMIN',
  WEB: 'WEB'
};
exports.default = platforms;