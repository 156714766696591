import { combineReducers } from "redux";
import showSpinner from "./spinnerReducer";
import user from "./userDataReducer";

// Combines all reducers to a single reducer function
const rootReducer = combineReducers({
  showSpinner,
  user
});

export default rootReducer;
