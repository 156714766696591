
import THEMES from 'shared/constants/themes';

export default {
  user: {
    isFetching: false,
    info: {
      theme: THEMES.WHITE
    }
  }
};
