'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUnreadNotificationsCountAction = exports.pushNotificationsDisconnectAction = exports.pushNotificationsConnectAction = undefined;
var _index = require('../constants/index');
var pushNotificationsConnectAction = exports.pushNotificationsConnectAction = function () {
  return {
    type: _index.ActionTypes.CONNECT_WEBSOCKET_NOTIFICATIONS
  };
};
var pushNotificationsDisconnectAction = exports.pushNotificationsDisconnectAction = function () {
  return {
    type: _index.ActionTypes.DISCONNECT_WEBSOCKET_NOTIFICATIONS
  };
};
var getUnreadNotificationsCountAction = exports.getUnreadNotificationsCountAction = function () {
  return {
    type: _index.ActionTypes.GET_UNREAD_NOTIFICATIONS_COUNT
  };
};