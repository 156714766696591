'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contactUsRequest = exports.fetchUserInformation = undefined;
var _constants = require('../constants');
var fetchUserInformation = exports.fetchUserInformation = function () {
  return {
    type: _constants.ActionTypes.USER_INFORMATION_REQUEST
  };
};
var contactUsRequest = exports.contactUsRequest = function (data) {
  return {
    type: _constants.ActionTypes.CONTACT_US_REQUEST,
    requestConfig: {
      data: data
    }
  };
};