'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionTypes = undefined;
var _keyMirror = require('fbjs/lib/keyMirror');
var _keyMirror2 = _interopRequireDefault(_keyMirror);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var ActionTypes = exports.ActionTypes = (0, _keyMirror2.default)({
  SHOW_SPINNER: undefined,
  HIDE_SPINNER: undefined,
  SET_ACTIVE_MODAL: undefined,
  CONNECT_WEBSOCKET_NOTIFICATIONS: undefined,
  DISCONNECT_WEBSOCKET_NOTIFICATIONS: undefined,
  GET_UNREAD_NOTIFICATIONS_COUNT_STARTED: undefined,
  GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS: undefined,
  GET_UNREAD_NOTIFICATIONS_COUNT: undefined,
  GET_UNREAD_NOTIFICATIONS_COUNT_FAILURE: undefined,
  GET_UNREAD_NOTIFICATIONS_COUNT_FINISHED: undefined,
  GET_PLATFORM_DETAILS_STARTED: undefined,
  GET_PLATFORM_DETAILS: undefined,
  GET_PLATFORM_DETAILS_SUCCESS: undefined,
  GET_PLATFORM_DETAILS_FAILURE: undefined,
  GET_PLATFORM_DETAILS_FINISHED: undefined,
  GET_WALLET_BALANCE_STARTED: undefined,
  GET_WALLET_BALANCE_SUCCESS: undefined,
  GET_WALLET_BALANCE: undefined,
  GET_WALLET_BALANCE_FAILURE: undefined,
  GET_WALLET_BALANCE_FINISHED: undefined,
  USER_INFORMATION_REQUEST_STARTED: undefined,
  USER_INFORMATION_REQUEST: undefined,
  USER_INFORMATION_REQUEST_SUCCESS: undefined,
  USER_INFORMATION_REQUEST_FAILURE: undefined,
  USER_INFORMATION_REQUEST_FINISHED: undefined,
  PUT_USER_PLATFORM_COMMISSION_STARTED: undefined,
  PUT_USER_PLATFORM_COMMISSION: undefined,
  PUT_USER_PLATFORM_COMMISSION_SUCCESS: undefined,
  PUT_USER_PLATFORM_COMMISSION_FAILURE: undefined,
  PUT_USER_PLATFORM_COMMISSION_FINISHED: undefined,
  GET_USER_PLATFORM_COMMISSION_STARTED: undefined,
  GET_USER_PLATFORM_COMMISSION: undefined,
  GET_USER_PLATFORM_COMMISSION_SUCCESS: undefined,
  GET_USER_PLATFORM_COMMISSION_FAILURE: undefined,
  GET_USER_PLATFORM_COMMISSION_FINISHED: undefined,
  SET_PROJECT_PUBLISHING_PRICE_STARTED: undefined,
  SET_PROJECT_PUBLISHING_PRICE: undefined,
  SET_PROJECT_PUBLISHING_PRICE_SUCCESS: undefined,
  SET_PROJECT_PUBLISHING_PRICE_FAILURE: undefined,
  SET_PROJECT_PUBLISHING_PRICE_FINISHED: undefined,
  GET_PROJECT_PUBLISHING_PRICE_STARTED: undefined,
  GET_PROJECT_PUBLISHING_PRICE: undefined,
  GET_PROJECT_PUBLISHING_PRICE_SUCCESS: undefined,
  GET_PROJECT_PUBLISHING_PRICE_FAILURE: undefined,
  GET_PROJECT_PUBLISHING_PRICE_FINISHED: undefined,
  SET_SUBSCRIPTION_PRICE_STARTED: undefined,
  SET_SUBSCRIPTION_PRICE: undefined,
  SET_SUBSCRIPTION_PRICE_SUCCESS: undefined,
  SET_SUBSCRIPTION_PRICE_FAILURE: undefined,
  SET_SUBSCRIPTION_PRICE_FINISHED: undefined,
  GET_SUBSCRIPTION_PRICE_STARTED: undefined,
  GET_SUBSCRIPTION_PRICE: undefined,
  GET_SUBSCRIPTION_PRICE_SUCCESS: undefined,
  GET_SUBSCRIPTION_PRICE_FAILURE: undefined,
  GET_SUBSCRIPTION_PRICE_FINISHED: undefined,
  PROJECT_FIRST_TIME_VIEW_CHECK_STARTED: undefined,
  PROJECT_FIRST_TIME_VIEW_CHECK_SUCCESS: undefined,
  PROJECT_FIRST_TIME_VIEW_CHECK: undefined,
  PROJECT_FIRST_TIME_VIEW_CHECK_FINISHED: undefined,
  PROJECT_FIRST_TIME_VIEW_CHECK_FAILURE: undefined,
  MODAL_REJECT_PROJECT_TOGGLE_STARTED: undefined,
  MODAL_REJECT_PROJECT_TOGGLE: undefined,
  MODAL_REJECT_PROJECT_TOGGLE_SUCCESS: undefined,
  MODAL_REJECT_PROJECT_TOGGLE_FAILURE: undefined,
  MODAL_REJECT_PROJECT_TOGGLE_FINISHED: undefined,
  IS_PROJECT_FINISHED_STARTED: undefined,
  IS_PROJECT_FINISHED: undefined,
  IS_PROJECT_FINISHED_SUCCESS: undefined,
  IS_PROJECT_FINISHED_FAILURE: undefined,
  IS_PROJECT_FINISHED_FINISHED: undefined,
  GET_FILE_CATEGORIES_STARTED: undefined,
  GET_FILE_CATEGORIES_SUCCESS: undefined,
  GET_FILE_CATEGORIES: undefined,
  GET_FILE_CATEGORIES_FAILURE: undefined,
  GET_FILE_CATEGORIES_FINISHED: undefined,
  CONTACT_US_REQUEST_STARTED: undefined,
  CONTACT_US_REQUEST_SUCCESS: undefined,
  CONTACT_US_REQUEST: undefined,
  CONTACT_US_REQUEST_FAILURE: undefined,
  CONTACT_US_REQUEST_FINISHED: undefined,
  CHART_REQUEST_STARTED: undefined,
  CHART_REQUEST_SUCCESS: undefined,
  CHART_REQUEST: undefined,
  CHART_REQUEST_FAILURE: undefined,
  CHART_REQUEST_FINISHED: undefined,
  CHART_REQUEST_USERS_STARTED: undefined,
  CHART_REQUEST_USERS_SUCCESS: undefined,
  CHART_REQUEST_USERS: undefined,
  CHART_REQUEST_USERS_FAILURE: undefined,
  CHART_REQUEST_USERS_FINISHED: undefined,
  CHART_PARAMS_CHANGED: undefined,
  CHART_PARAMS_SET: undefined,
  CHART_OPEN_DETAILED_VIEW: undefined,
  GET_DASHBOARD_STRUCTURE_STARTED: undefined,
  GET_DASHBOARD_STRUCTURE_SUCCESS: undefined,
  GET_DASHBOARD_STRUCTURE: undefined,
  GET_DASHBOARD_STRUCTURE_FAILURE: undefined,
  GET_DASHBOARD_STRUCTURE_FINISHED: undefined,
  GET_ALL_TAGS_STARTED: undefined,
  GET_ALL_TAGS_SUCCESS: undefined,
  GET_ALL_TAGS: undefined,
  GET_ALL_TAGS_FAILURE: undefined,
  GET_ALL_TAGS_FINISHED: undefined,
  GET_REGIONS_STARTED: undefined,
  GET_REGIONS_SUCCESS: undefined,
  GET_REGIONS: undefined,
  GET_REGIONS_FAILURE: undefined,
  FETCH_CURRENCIES_STARTED: undefined,
  FETCH_CURRENCIES_SUCCESS: undefined,
  FETCH_CURRENCIES: undefined,
  FETCH_CURRENCIES_FAILURE: undefined,
  GET_SUBDOMAINS: undefined,
  GET_SUBDOMAINS_SUCCESS: undefined,
  GET_SP_PLATFORM_CONFIG_STARTED: undefined,
  GET_SP_PLATFORM_CONFIG: undefined,
  GET_SP_PLATFORM_CONFIG_SUCCESS: undefined,
  GET_SP_PLATFORM_CONFIG_FAILURE: undefined

  // REPAY_MONEY_STARTED: undefined,
  // REPAY_MONEY_SUCCESS: undefined,
  // REPAY_MONEY: undefined,
  // REPAY_MONEY_FAILURE: undefined,
  // REPAY_MONEY_FINISHED: undefined,

  // TOGGLE_REPAY_MODAL: undefined,
});