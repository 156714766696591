'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPlatformDetails = undefined;
var _constants = require('../constants');
var getPlatformDetails = exports.getPlatformDetails = function () {
  return {
    type: _constants.ActionTypes.GET_PLATFORM_DETAILS
  };
};