'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  black: '#000000',
  white: '#ffffff',
  red: {
    base: '#ff0000',
    light: '#fce4d3'
  },
  yellow: '#fff7c9',
  violet: '#e6f0ff',
  turquoise: '#5cc7e1',
  green: {
    base: '#43AE8F',
    light: '#e5eedc'
  },
  blue: {
    base: '#1496af',
    light: '#30cada',
    lighter: '#bdedf9',
    lighten: '#a4d2db',
    lightness: '#eef8f9',
    dark: '#006880',
    potty: '#f3f9fb',
    gossamer: '#f7fbfc',
    mabel: '#d8f7ff'
  },
  gray: {
    base: '#4c4c4c',
    light: '#939393',
    lighter: '#999999',
    lighten: '#d9d9d9',
    lightness: '#5f5f5f',
    dark: '#2d2d2d',
    darker: '#2f2f2f',
    darken: '#404040',
    gossamer: '#212121',
    neutral: '#292929'
  },
  silver: {
    base: '#f1f1f1',
    light: '#f7f7f7',
    lighter: '#EEEEEE',
    lighten: '#c7c7c7',
    dark: '#d8d8d8',
    darker: '#454545',
    darken: '#8e8e8e'
  },
  brown: {
    lite: '#555454'
  }
};