import React from 'react';
import { Route, Router, Switch } from 'react-router';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import asyncComponent from 'shared/utils/asyncComponent';
import * as pathname from 'shared/routes/urlLocations';
import { history } from 'shared/routes/urlLocations';

// Layouts
const AsyncUnauthorizedStubLayout = asyncComponent(() => import('../layouts/UnauthorizedStubLayout'));
const AsyncErrorLayout = asyncComponent(() => import('../layouts/ErrorLayout'));

// Pages
const AsyncPrivacyPolicyPage = asyncComponent(() => import('../containers/PrivacyPolicyPage'));
const AsyncCookiesPage = asyncComponent(() => import('../containers/CookiesPage'));
const AsyncTermsOfUsePage = asyncComponent(() => import('../containers/TermsOfUsePage'));
const AsyncNotFoundPage = asyncComponent(() => import('../containers/NotFoundPage'));

// Routes
export default () => (
  <Router history={history}>
    <LocaleProvider locale={enUS}>
      <Switch>
        <Route
            path={pathname.stubPage} // privacy
            exact
            render={route => (
            <AsyncUnauthorizedStubLayout>
              <AsyncPrivacyPolicyPage route={route} />
            </AsyncUnauthorizedStubLayout>
            )}
        />
        <Route
            path={pathname.privacy} // stubPage
            exact
            render={route => (
            <AsyncUnauthorizedStubLayout>
              <AsyncPrivacyPolicyPage route={route} />
            </AsyncUnauthorizedStubLayout>
            )}
        />
        <Route
            path={pathname.cookie}
            exact
            render={route => (
            <AsyncUnauthorizedStubLayout>
              <AsyncCookiesPage route={route} />
            </AsyncUnauthorizedStubLayout>
            )}
        />
        <Route
            path={pathname.termsOfUse}
            exact
            render={route => (
            <AsyncUnauthorizedStubLayout>
              <AsyncTermsOfUsePage route={route} />
            </AsyncUnauthorizedStubLayout>
            )}
        />
        <Route
            render={() => (
            <AsyncErrorLayout>
              <AsyncNotFoundPage />
            </AsyncErrorLayout>
            )}
        />
      </Switch>
    </LocaleProvider>
  </Router>
);
