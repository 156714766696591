'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _colors = require('./colors');
var _colors2 = _interopRequireDefault(_colors);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var platformPrimaryDefaultColor = _colors2.default.blue.base;
exports.default = {
  primary: _colors2.default.blue.base,
  primaryAccent: _colors2.default.blue.dark,
  platformPrimary: platformPrimaryDefaultColor,
  secondary: _colors2.default.silver.light,
  error: _colors2.default.red.base,
  success: _colors2.default.green.base,
  gray: _colors2.default.gray.base,
  hint: _colors2.default.gray.light
};