'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _react = require('react');
var _react2 = _interopRequireDefault(_react);
var _reactAsyncComponent = require('react-async-component');
var _Spinner = require('../components/Spinner');
var _Spinner2 = _interopRequireDefault(_Spinner);
var _urlLocations = require('../routes/urlLocations');
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
exports.default = function (importComponent) {
  return (0, _reactAsyncComponent.asyncComponent)({
    resolve: importComponent,
    LoadingComponent: function LoadingComponent() {
      return _react2.default.createElement(_Spinner2.default, null);
    },
    ErrorComponent: function ErrorComponent() {
      return window.location.reload(true);
    }
  });
};