'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var protocol = 'https:';
var version = exports.version = 'v2';
var apiUrl = exports.apiUrl = "".concat(protocol, "//").concat(window.API_URL);
exports.default = {
  apiUrl: apiUrl,
  version: version,
  protocol: protocol,
  apiConfigUrl: "".concat(apiUrl, "/user/api/").concat(version, "/configs"),
  apiUserUrl: "".concat(apiUrl, "/user/api/").concat(version),
  publicUserUrl: "".concat(apiUrl, "/user/api/").concat(version, "/public"),
  apiUrlAuth: "".concat(protocol, "//").concat(window.API_URL_AUTH),
  apiProjectUrl: "".concat(apiUrl, "/project/api/").concat(version),
  apiPublicFilesUrl: "".concat(apiUrl, "/file/api/").concat(version, "/public/storage/images"),
  apiFilesUrl: "".concat(apiUrl, "/file/api/").concat(version, "/storage"),
  apiNotificationsUrl: "".concat(apiUrl, "/user/api/").concat(version, "/notifications"),
  apiPaymentsUrl: "".concat(apiUrl, "/user/api/").concat(version, "/mango-pay"),
  apiProjectFilesUrl: "".concat(apiUrl, "/file/api/").concat(version, "/storage/projects"),
  pushNotificationUrl: "wss://".concat(window.API_URL, "/user-ws/api/").concat(version, "/ws"),
  apiPlatformConfigUrl: "".concat(apiUrl, "/user/api/").concat(version, "/platform-configs"),
  name: '',
  prefix: '',
  footerText: '',
  logoSrc: 'assets/lamp.svg',
  logoText: '',
  needLogin: false
};
var LoginConfig = exports.LoginConfig = {
  CLIENT_ID: 'web-console',
  CLIENT_SECRET: '7699d8c8-f5ca-45c5-b52e-4c50d2e554c4',
  HEADERS: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  PARAMS: {
    auth: {
      response_type: 'code',
      client_id: 'web-console',
      redirect_uri: "".concat(window.location.protocol, "//").concat(window.location.host, "/callback")
    },
    token: {
      grant_type: 'authorization_code',
      state: 234,
      redirect_uri: "".concat(window.location.protocol, "//").concat(window.location.host, "/callback")
    },
    refreshToken: {
      grant_type: 'refresh_token',
      state: 234
    }
  }
};