"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
require("./Spinner.css");
var _react = require("react");
var _react2 = _interopRequireDefault(_react);
var _antd = require("antd");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var Spinner = function Spinner(_ref) {
  var showSpinner = _ref.showSpinner;
  window.onbeforeunload = function () {
    if (showSpinner) {
      return true;
    }
  };
  return showSpinner ? _react2.default.createElement(_antd.Spin, {
    spinning: true,
    size: "large"
  }) : null;
};
exports.default = Spinner;